@import '~@sonnen/shared-web/abstracts';

.c-customer-list {
  position: relative;
}

.c-customer-list__container {
  padding-top: 0;
}

.c-customer-list__headline-wrapper {
  padding-top: var(--gap-3);
}

.c-customer-list__pagination {
  flex-flow: initial;
  justify-content: center;

  @include breakpointUp(XS) {
    padding: var(--gap-6) 0 0;
  }
}

.c-customer-list__search-field {
  position: relative;
}

.c-customer-list__loader {
  position: absolute;
  top: 50%;
  right: -85px;
  margin-top: -2px;
  transform: translateY(-50%);
}

.c-customer-list__no-results {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-1);

  @include breakpointUp(XS) {
    margin-top: var(--gap-3);
  }
}

.c-customer-list__missing-battery {
  padding: 0;
}
