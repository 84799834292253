@import '~@sonnen/shared-web/abstracts';

.c-battery-live-card {
  &__software {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include breakpointDown(MD) {
      flex-flow: column wrap;
    }
  }

  &__loader {
    margin: var(--gap-1) 0 var(--gap-2);
  }

  &__link-arrow {
    transform: translateY(.2em) rotate(180deg);
    height: 12px;
    fill: getColor('blue');
    margin-left: 5px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__footer {
    line-height: 24px;
    font-size: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin: var(--gap-1) 0px;
  }

  &__header-status {
    margin-left: var(--gap-1);

    @include breakpointUp(MD) {
      margin-left: var(--gap-3);
    }
  }

  &__subtitle {
    margin-top: 32px;
    margin-left: 24px;
  }
}
