@import '~@sonnen/shared-web/abstracts';

.c-customers-table-contract__container {
  width: 100%;
}

.c-customers-table-contract__row {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin-right: var(--gap-3);
  padding-bottom: 4px;

  @include breakpointUp(XS) {
    flex-direction: column;
    padding-bottom: 0;
  }
}

.c-customers-table-contract__warnings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
