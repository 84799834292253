@import '~@sonnen/shared-web/abstracts';

.c-customers-table-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 15%;
  margin-bottom: 8px;
  font-weight: 420;
  color: getTextColor('dark');
}

.c-customers-table-name__lastname {
  text-transform: uppercase;
}
