@import '~@sonnen/shared-web/abstracts';
@import '../../components/CustomersTable/Customers.Table.utils.scss';

.c-customers-table-header {
  display: none;

  @include breakpointUp(XS) {
    display: flex;
    position: sticky;
    top: var(--header-height);
    border-top: 2px solid getColor('grey-light');
    border-bottom: 2px solid getColor('grey-light');
    background-color: getColor('white');
    overflow-x: hidden;
    z-index: var(--z-index-banner);
  }
}

.c-customers-table-header__inner {
  display: flex;
  flex: 1 0 0;
  max-width: 100%;

  @include breakpointUp(XS) {
    min-width: 1100px;
    padding: 15px var(--gap-4);
  }
}

.c-customers-table-header__cell {
  font-weight: 420;
  color: getTextColor('dark');
  align-items: center;
}

.c-customers-table-header__cell--name {
  flex: 0 0 15%;
}
.c-customers-table-header__cell--location,
.c-customers-table-header__cell--battery,
.c-customers-table-header__cell--contract {
  flex: 2;
}

.c-customers-table-header__cell--note {
  flex: 1;
}

.c-customers-table-header__cell--case {
  flex: 1;
  justify-content: center;
}

.c-customers-table-header__cell-label {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 0 10px;
}
