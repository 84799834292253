@import '~@sonnen/shared-web/abstracts';

$_color-hover: #f1f1f1;

.customers-table-user {
  position: relative;
  flex-direction: column;

  @include breakpointUp(XS) {
    min-width: 1100px;
  }

  &__delimiter {
    position: relative;
    top: var(--gap-2);
    height: 1px;
    background-color: getColor('grey-lighter');

    &:last-child {
      display: none;
    }
  }

  &__row {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: var(--gap-2) var(--gap-4);

    @include breakpointUp(XS) {
      flex-direction: row;
      padding: var(--gap-2) var(--gap-4);

    }

    &--enabled {
      &:hover {
        background-color: $_color-hover;
        cursor: pointer;
      }
    }
  }
}