@import '~@sonnen/shared-web/abstracts';

.c-missing-battery-button {
  display:flex;
  flex-direction: column;
}

.c-missing-battery-button__info {
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  color: getTextColor('grey');

  @include breakpointDown(XS) {
    margin-top: var(--gap-2);
  }
}

.c-missing-battery-button__content {
  margin: var(--gap-2) 0;
}
