@import '~@sonnen/shared-web/abstracts';
@import '../../components/CustomersTable/Customers.Table.utils.scss';


.c-customers-table-location {
  flex: 2;
  --min-height: 70px;

  @include breakpointUp(XS) {
    --min-height: 35px;
  }
}

.c-customers-table-location {
  font-weight: initial;
  color: getTextColor('dark');
  min-height: 20px;
}

.c-customers-table-location__inner {
  display: flex;

  @include breakpointUp(XS) {
    display: block;
    min-height: var(--min-height);
    padding: 0;
    margin: 0 var(--gutter-h) 0 0;
  }
}
