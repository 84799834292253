@import '~@sonnen/shared-web/abstracts';

.c-customers-table-access-info {
  display: flex;
  align-items: stretch;
  max-width: 820px;
  padding: 5px;
  margin: 0 var(--gap-4) var(--gap-2) var(--gap-4);
  @include boxShadow();
}

.c-customers-table-access-info__padlock-icon {
  width: 12px;
  transform: rotate(180deg);
}

.c-customers-table-access-info__padlock {
  flex: 0 0 auto;
  align-self: center;
  margin: 0 4px;
  @include breakpointUp(SM) {
    margin: 0 var(--gap-1);
  }
}

.c-customers-table-access-info__title {
  font-weight: 420;
}

.c-customers-table-access-info__content {
  line-height: 1.5;
  margin: 0;
  padding: 8px 4px;
  @include breakpointUp(SM) {
    padding: 0;
  }
}

.c-customers-table-access-info__more {
  margin: 0 var(--gap-2);
}

.c-customers-table-access-info__close-icon {
  width: 14px;
}

.c-customers-table-access-info__close {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  margin: var(--gap-1);
}

.c-customers-table-access-info__close-button {
  display: inline-flex;
}

.c-customers-table-access-info__link {
  @include breakpointUp(SM) {
    margin-left: var(--gap-1);
  }
}

