@import '~@sonnen/shared-web/abstracts';

.c-customers-table {
  @include boxShadow;
  margin: 0 calc(var(--gutter-layout) * -1);

  @include breakpointUp(XS) {
    width: 100%;
    margin: auto;
    box-shadow: none;
    overflow-x: scroll;
  }

  @include breakpointUp(LG) {
    overflow-x: visible;
  }
}

.c-customers-table--loading {
  overflow-x: visible;
  border: none;
}

.c-customers-table__inner {
  font-size: 14px;

  @include breakpointUp(XS) {
    min-width: calc(#{config(BREAKPOINTS, MD)} - var(--gap-4) * 2);
  }
}

.c-customers-table__no-results {
  display: flex;
  justify-content: center;
  margin-top: var(--gap-1);

  @include breakpointUp(XS) {
    margin-top: var(--gap-8);
  }
}

.c-customers-table__paragraph {
  margin: 0;
}

.c-customers-table__paragraph--smaller {
  font-size: 12px;
}

.c-customers-table__icon {
  @include createIcon($_width: 16px);
}