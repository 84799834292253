@import '~@sonnen/shared-web/abstracts';

.c-customers-table-access-badge {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: var(--gap-2) 0;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  background-color: getColor('grey-lighter');
  color: getTextColor('grey-dark');
  text-transform: uppercase;
}

.c-customers-table-access-badge__icon {
  width: 10px;
  margin-right: 4px;
  fill: getTextColor('grey-dark');
}
