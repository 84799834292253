@import '~@sonnen/shared-web/abstracts';
@import '../../components/CustomersTable/Customers.Table.utils.scss';

.c-customers-table-no-data {
  display: none;

  @include breakpointUp(XS) {
    flex: 2;
    display: block;
    padding-top: var(--gap-1);
    font-weight: 300;
    color: getTextColor('dark');
  }
}
