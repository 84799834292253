@import '~@sonnen/shared-web/abstracts';

.c-customer-table-note__form {
  position: relative;
}

.c-customer-table-note__form--edit-active {
  @include breakpointUp(SM) {
    position: absolute;
  }
}

.c-customer-table-note__input {
  position: relative;

  .sw-input {
    padding: 0 0 2px;
  }

  .sw-input__field {
    background: transparent;
    min-height: 50px;
    font-size: 12px;
    font-weight: 300;
    color: getTextColor('dark');
    padding: var(--gap-1) var(--gap-4) 0 var(--gap-1);
    transition: none;
    cursor: auto;
  }

  .sw-input__field--disabled {
    color: getTextColor('grey');
    padding: 10px 36px 10px 18px;
    border: solid 1.5px transparent;
    border-radius: 0;
    transition: none;

    &:hover {
      border: solid 1.5px getColor('grey-lighter');
      border-radius: var(--border-radius);
      transition: none;
      cursor: pointer;
    }
  }

  .sw-input__field--textarea {
    
    &::placeholder {
      color: getTextColor('dark');
    }
  }
}

.c-customer-table-note__input--edit-active {
  z-index: 20;

  .sw-input__field--textarea {
    padding-bottom: var(--gap-6);
    min-width: 240px;
    min-height: 150px;
    background-color: getColor('white');
  }

  .sw-input__field {
    border: solid 2px getColor('blue');
  }
}

.c-customer-table-note__footer {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  padding: 10px 5px 10px 0;
  background-color: getColor('white');
  align-items: center;
  justify-content: flex-end;
  border: 2px solid getColor('blue');
  border-top: none;
  cursor: auto;
}

.c-customer-table-note__save {
  margin-right: var(--gap-2);
  color: getTextColor('blue');
}

.c-customer-table-note__cancel {
  margin-right: var(--gap-2);
  color: getTextColor('blue');
}

.c-customer-table-note__edit-icon {
  @include createIcon($_width: 16px, $_color: getColor('blue'));

  position: absolute;
  top: var(--gap-1);
  right: var(--gap-2);
  height: var(--gap-2);
  pointer-events: none;
  cursor: pointer;
}
