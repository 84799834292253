@import '~@sonnen/shared-web/abstracts';
@import '../../components/CustomersTable/Customers.Table.utils.scss';

.c-customers-table-site {
  width: 100%;
}

.c-customers-table-site__table-cell {
  position: relative;
  flex-direction: column;
  padding: 1px var(--gap-3) 0 0;
  margin: 0;

  @include breakpointUp(XS) {
    flex-direction: row;
    padding: 0;
    border-bottom: none;
  }
}

.c-customers-table-site__container {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  padding: var(--gap-2) 0 0;

  @include breakpointUp(XS) {
    flex-direction: row;
    padding: 0;
  }
}

.c-customers-table-site__battery,
.c-customers-table-site__contract {
  flex: 2;
  display: flex;
  padding-top: var(--gap-1);
  font-weight: 300;
  color: getTextColor('dark');

  @include breakpointUp(XS) {
    display: block;
    padding: 0;
  }
}

.c-customers-table-site__case {
  position: absolute;
  top: var(--gap-4);
  right: var(--gap-4);

  @include breakpointUp(XS) {
    position: relative;
    top: 0;
    right: 0;
    flex: 1;
    justify-content: center;
  }
}

.c-customers-table-site__note {
  @include breakpointUp(XS) {
    flex: 1;
  }
}