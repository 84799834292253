@import '~@sonnen/shared-web/abstracts';
@import '../../components/CustomersTable/Customers.Table.utils.scss';

.c-customers-table-battery {
  flex: 2;
}

.c-customers-table-battery__container {
  width: 100%;
}

.c-customers-table-battery__row {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: space-between;
  margin-right: var(--gap-3);
  padding-bottom: 4px;

  @include breakpointUp(SM) {
    padding-bottom: 0;
  }
}

.c-customers-table-battery__p--bold {
  @include breakpointUp(XS) {
    font-weight: 420;
  }
}

.c-customers-table-battery__P--fixed {
  margin-left: var(--gap-3);
  white-space: nowrap;

  @include breakpointUp(XS) {
    margin-left: 0;
  }
}

.c-customers-table__warnings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
